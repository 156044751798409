$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; $show_on: unquote("top_menu");$plugin_outlet: unquote("below-site-header");$background_image_light: unquote("");$background_image_dark: unquote("");$tile_background_image: unquote("false");$show_for: unquote("everyone");$special_style: unquote("basic style (default)");// Theme: discourse-search-banner
// Target: common scss
// Last Edited: 2025-02-05 11:54:32 UTC
@import "common/foundation/mixins";

$max-width: 600px;

.display-search-banner {
  #main-outlet {
    padding-top: 0;
  }
}

.search-banner {
  background-image: var(--custom-bg);

  @if $tile-background_image == "true" {
    background-size: auto;
  } @else {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

// hide search icon from default search menu
.search-menu.glimmer-search-menu .search-icon {
  display: none;
}

.custom-search-banner-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 2.5em 0 3em;
  margin: 1em auto;
  @include breakpoint(tablet) {
    padding: 1em 8px 1.25em;
    margin-top: 0.5em;
  }

  > div {
    margin: 0 auto;
    max-width: $max-width;
  }

  .search-menu {
    display: flex;
    position: relative;

    .search-menu-container {
      width: 100%;
      min-width: 0;
    }
  }

  .menu-panel-results .menu-panel {
    position: unset;
    padding: 0;
  }

  .d-icon-search {
    margin: 0;
  }
  .browser-search-tip {
    display: none;
  }

  .search-input {
    #search-term {
      min-width: 0;
      flex: 1 1;
    }
  }

  h1 {
    font-size: var(--font-up-6);
    line-height: $line-height-medium;
    @include breakpoint(tablet) {
      font-size: var(--font-up-4);
    }
  }

  h1,
  p {
    text-align: center;
  }

  .btn.search-icon:not(.has-search-button-text) {
    z-index: 2;
    background: transparent;
    line-height: 1;
    color: var(--primary-medium);
    height: 100%;
    position: absolute;
    left: 0;
    .rtl & {
      right: 0;
      left: unset;
    }

    .discourse-no-touch & {
      &:hover {
        background: transparent;
        color: var(--primary);
        .d-icon {
          color: currentColor;
        }
      }
    }
    + .search-menu-container .search-input {
      padding-left: 1.75em;
      .rtl & {
        padding-left: unset;
        padding-right: 1.75em;
      }
    }
    + .search-menu-container .search-input .search-context {
      margin-left: 4px;
    }
  }
  .btn.search-icon.has-search-button-text {
    order: 2;
    margin-left: 0.5em;
    column-gap: 0.5em;
    background-color: var(--tertiary);
    color: var(--secondary);
    flex: none;
    &:hover {
      background-color: var(--tertiary-hover);
    }
    .d-icon {
      color: var(--secondary);
    }
  }

  .results {
    box-sizing: border-box;
    background: var(--secondary);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    position: absolute;
    z-index: 9;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    top: 2.75em;
    right: 0;
    padding: 0.5em;
    @include breakpoint(mobile-extra-large) {
      width: 100%;
    }
    ul,
    ol {
      list-style-type: none;
      margin: 0;
    }
    .d-icon-search {
      display: none;
    }
  }

  .search-link .d-icon {
    color: var(--primary-medium);
  }

  span.keyword {
    color: var(--primary);
  }
}

// these are add-on styles controlled by settings
@import "special-styles";
